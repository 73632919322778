import { BsHeart, BsFillHeartFill } from 'react-icons/bs';

export const PictureOfTheDay: React.FC<{
  date: string;
  title: string;
  mediaUrl: string;
  mediaType: 'image' | 'video';
  isLiked: boolean;
  setIsLiked: (isLiked: boolean) => void;
}> = ({ date, title, mediaUrl, mediaType, isLiked, setIsLiked }) => {
  return (
    <figure className='card' key={date}>
      {mediaType === 'image' ? (
        <img className='media' src={mediaUrl} alt='' />
      ) : (
        <iframe
          className='media'
          title={title}
          src={mediaUrl}
          allowFullScreen
        />
      )}
      <figcaption>
        <h2 className='picture-of-the-day-title'>{title}</h2>
        <div className='like-and-date-wrapper'>
          <button className='like-button' onClick={() => setIsLiked(!isLiked)}>
            {isLiked ? (
              <BsFillHeartFill size={18} color='rgb(237, 73, 86)' />
            ) : (
              <BsHeart size={18} />
            )}
          </button>
          <time className='date-of-capture'>Date of capture: {date}</time>
        </div>
      </figcaption>
    </figure>
  );
};
