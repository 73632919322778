import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ReactComponent as GithubCorner } from './github-corner-right.svg';

import { PictureOfTheDay } from './components/PictureOfTheDay';
import { useLocalStorage } from './hooks/useLocalStorage';

type SpacestagramType = {
  copyright?: string;
  date: string;
  hdurl: string;
  media_type: 'image' | 'video';
  service_version: string;
  title: string;
  url: string;
};

function App() {
  const [data, setData] = useState<Array<SpacestagramType>>([]);

  const [likedItems, setLikedItems] = useLocalStorage<Array<string>>(
    'likedItems',
    []
  );

  const [endDate, setEndDate] = useState<DateTime>(DateTime.now());
  const startDate = endDate.minus({ days: 8 });

  const endDateString = endDate.toFormat('yyyy-MM-dd');
  const startDateString = startDate.toFormat('yyyy-MM-dd');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://spacestagram.ellanan.com/api/apod?api_key=${process.env.REACT_APP_NASA_API_KEY}&start_date=${startDateString}&end_date=${endDateString}`
        );
        const result = await response.json();
        setData((previousData) => {
          return [...previousData, ...result];
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [startDateString, endDateString]);

  return (
    <>
      <a
        href='https://github.com/ellanan/spacestagram'
        target='_blank'
        rel='noreferrer'
      >
        <GithubCorner className='github-corner' />
      </a>
      <div className='main-wrapper'>
        <h1 className='spacestagram-title'>Spacestagram</h1>
        <span className='attribution'>
          Brought to you by NASA's Astronomy Photo of the Day (APOD) API
        </span>
        <div className='card-wrapper'>
          {_.orderBy(data, ['date'], ['desc'])?.map((item) => (
            <PictureOfTheDay
              key={item.date}
              date={item.date}
              title={item.title}
              mediaUrl={item.url}
              mediaType={item.media_type}
              isLiked={likedItems.includes(item.date)}
              setIsLiked={(isLiked) => {
                setLikedItems(
                  isLiked
                    ? [...likedItems, item.date]
                    : _.without(likedItems, item.date)
                );
              }}
            />
          ))}
        </div>
        <button
          className='load-more-button'
          onClick={() => setEndDate(endDate.minus({ days: 9 }))}
        >
          load more
        </button>
      </div>
    </>
  );
}

export default App;
